<template>
  <div>
    <div class="my-5 pt-sm-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="mb-5 text-center">
              <img src="@/assets/images/logo.svg" alt="logo" height="50" />
            </div>

            <div class="container">
              <div class="row">
                <div class="col-sm-10 offset-sm-1 col-xs-12">
                  <div class="card">
                    <div class="card-body">
                      <div v-if="user != null && $isAdmin()">
                        <b>{{ user.name }}</b>

                        <hr />
                      </div>
                      <!--  -->
                      <div v-for="(survey, index) in questions" :key="index">
                        <strong class="d-block mt-2 mb-1">
                          {{ index + 1 }}.
                          {{ questions[index].question }}
                        </strong>
                        <div class="d-flex">
                          <div class="col" v-if="questions[index].type != 3">
                            > {{ questions[index].answer }}
                          </div>
                          <div class="col" v-if="questions[index].type == 3">
                            <span
                              v-for="(opt, optIndex) in questions[index].answer"
                              :key="optIndex"
                            >
                              {{ optIndex + 1 }}. {{ opt }}<br
                            /></span>
                          </div>
                        </div>
                      </div>
                      <!--  -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";

export default {
  name: "survey-answer",
  data() {
    return { questions: [], user: null };
  },
  created() {
    this.getData();
  },

  methods: {
    getData() {
      HTTP.get("public/surveys/" + this.$route.params.shortlink).then(
        (result) => {
          let questions = JSON.parse(result.data[0].answers);
          this.questions = questions;
          this.user = result.data[0].user;
        }
      );
    },
    add: function () {
      this.list.push({ name: "" });
    },
    replace: function () {
      this.list = [{ name: "" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
  },
};
</script>